import React from 'react';
import logo from './../../images/coffeeandBook.png'
import './../About/About.css'

// React component for the About section
const About = () => {
  return (
    <section style={styles.aboutContainer}>
      {/* Text content */}
      <div style={styles.textContent}>
        <h1 style={styles.title}>Who We Are</h1>
        <p style={styles.paragraph}>
          At <strong>Astori Games</strong>, we are a video game development studio dedicated to crafting unforgettable experiences for players worldwide. 
        </p>
        <p style={styles.paragraph}>
          Our mission is to redefine what it means to tell stories through games. We believe in the power of interactive media to shape culture, challenge perspectives, and spark creativity. 
          By combining compelling storytelling with gameplay mechanics, we strive to produce games that leave a profound impact long after the screen goes dark. 
        </p>
      </div>

      {/* Image content */}
      <img src={logo} alt="AboutLogo" className="hide-on-mobile" style={styles.image} />
    </section>
  );
};

// Inline styles
const styles = {
  aboutContainer: {
    display: 'flex',
    flexDirection: 'row-reverse', // Image on the right
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#282c34',
    borderRadius: '10px',
    padding: '40px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    margin: '20px auto',
    maxWidth: '1200px',
    transition: 'transform 0.3s ease-in-out'
  },
  textContent: {
    maxWidth: '600px',
  },
  title: {
    fontSize: '2rem',
    color: '#2a632c',
    marginBottom: '15px',
  },
  paragraph: {
    fontSize: '1.1rem',
    lineHeight: '1.6',
    color: '#FFF',
    marginBottom: '20px',
  },
  image: {
    width: '50%',
    height: 'auto',
    borderRadius: '8px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out',
    
  }
};

// Media query-like functionality using window resize in React
window.addEventListener('resize', function () {
  if (window.innerWidth <= 768) {
    styles.aboutContainer.flexDirection = 'column'; // Stacks the content on smaller screens
    styles.image.width = '100%'; // Makes the image take the full width on mobile
  } else {
    styles.aboutContainer.flexDirection = 'row-reverse'; // Default for large screens
    styles.image.width = '50%'; // Default image size for larger screens
  }
});

export default About;
