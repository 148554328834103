import React from 'react';
import NewsCard from '../NewsCards/NewsCards';
import './../NewsArticles/NewsArticles.css'; // Add your styles for NewsArticles
import image1 from './../../images/sunset.jpg';
import image2 from './../../images/nightsky.png';
import image3 from './../../images/tree.png'

const NewsArticles = () => {
  // Example news data, can be fetched from an API
  const articles = [
    {
      image: image1,
      title: 'Game Announcement',
      description: 'Do we have a project coming out soon? Who knows? Maybe this year, maybe 10.',
    },
    {
      image: image2,
      title: 'News about cool New Game',
      description: 'This is where we would put any cool information about the cool new game. This will have all the information needed so you know the press release is cool',
    },
    {
      image: image3,
      title: 'Do you think it will do well?',
      description: 'Do we say cool too much? Maybe, but that is what makes us cool!',
    },
    // Add more articles as needed
  ];

  return (
    <div className="news-articles">
      {articles.map((article, index) => (
        <NewsCard
          key={index}
          image={article.image}
          title={article.title}
          description={article.description}
        />
      ))}
    </div>
  );
};

export default NewsArticles;
