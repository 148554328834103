import React, { useState } from 'react';
import './Header.css'; // Ensure you link to your CSS file

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the visibility of the menu on mobile
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="logo">
      </div>

      {/* Hamburger menu for mobile */}
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Navigation links (desktop) */}
      <nav>
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </nav>

      {/* Mobile navigation panel (hidden initially) */}
      <div className={`nav-links ${menuOpen ? 'active' : ''}`}>
        <a href="#home" onClick={() => setMenuOpen(false)}>Home</a>
        <a href="#about" onClick={() => setMenuOpen(false)}>About</a>
        <a href="#contact" onClick={() => setMenuOpen(false)}>Contact</a>
      </div>
    </header>
  );
};

export default Header;
