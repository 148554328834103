import React from 'react';
import './NewsCards.css'; // Add your styles for NewsCard

const NewsCard = ({ image, title, description }) => {
  return (
    <div className="news-card">
      <img src={image} alt={title} className="news-card-image" />
      <div className="news-card-content">
        <h3 className="news-card-title">{title}</h3>
        <p className="news-card-description">{description}</p>
        <button className="news-card-readmore">Read More</button>
      </div>
    </div>
  );
};

export default NewsCard;
