import React from 'react';
import "./App.css"
import Header from './components/Header/Header';
import Hero from './components/HeroBox/Hero';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import NewsArticles from './components/NewsArticles/NewsArticles';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      {/* Other sections like About, Games, etc. */}
      <About />
      <NewsArticles />
      <Footer />
    </div>
  );
}

export default App;
